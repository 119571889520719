import React from 'react';
import styles from './Main.module.css';
import Search from './Search';

function Main({config}) {


  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={`${styles.column} ${styles['column-1']}`}> </div>
        <div className={`${styles.column} ${styles['column-2']}`}>logo</div>
        <div className={`${styles.column} ${styles['column-3']}`}>
             <div>{config && <Search config={config.earthday3} personas={config.drone}/>}</div>
             <div className={styles.spacer}></div>
        </div>
        <div className={`${styles.column} ${styles['column-4']}`}> </div>
      </div>
      <div className={styles.footer}></div>
    </div>
  );
}

export default Main;

//
